const { name } = require('../../package');
import baseURL from "@/api/baseUrl";
import useCommon from '@/utils/common';
export function getGlobalName() {
    return name;
}
export function getBaseUrl() {
    return process.env.NODE_ENV === "development" ? baseURL : IOT_SERVICE_CONFIG.baseUrl;
}
export function getPicUrlArray(url) {
    const imgList = url.map((item) => {
        return getBasePicUrl() + item;
    });
    return imgList;
}
export function getPicUrl(url) {
    return getBasePicUrl() + url;
}
export function getBasePicUrl() {
    let baseURL = getBaseUrl();
    baseURL = baseURL.slice(0, baseURL.length - 5) + ':8000';
    return baseURL;
}
export function getApiUrl(url) {
    // 判断url 是否有用http
    if (url?.slice(0, 4) === "http" || url?.slice(0, 5) === "https") {
        return url;
    }
    return getBaseUrl() + url;
}
export function getLSObj() {
    const { bsjLocalStorage } = useCommon();
    return new bsjLocalStorage(getGlobalName());
}
export function toTreeData(data, attributes) {
    const dataCopy = JSON.parse(JSON.stringify(data));
    if (!dataCopy)
        return [];
    // toTree
    const map = new Map(dataCopy.map((item) => [item[attributes.id], item]));
    const treeData = [];
    dataCopy.map((item) => {
        const parent = map.get(item[attributes.parentId]);
        if (parent) {
            (parent.children || (parent.children = [])).push(item); // parent 引用 item
        }
        else {
            treeData.push(item);
        }
    });
    return treeData;
}
let dataList = [];
// 将树形节点改为一维数组
export function generateList(data, idAndTitleKey, init) {
    if (init) {
        dataList = [];
    }
    for (let i = 0; i < data.length; i++) {
        const node = data[i];
        const title = node[idAndTitleKey.title];
        const key = node[idAndTitleKey.key];
        dataList.push({ key, title: title });
        if (node.children) {
            generateList(node.children, idAndTitleKey, false);
        }
    }
    return dataList;
}
export function getTerminalType(type) {
    switch (type) {
        case 0:
            return '门磁';
        default:
            return '-';
    }
}
export function getAlarmTypeArray() {
    return [
        { value: 2, label: '开门报警' },
        { value: 4, label: '拆除报警' },
        { value: 6, label: '低压故障' },
        { value: 12, label: '紧急报警' },
    ];
}
export function getAlarmType(type) {
    const typeArray = getAlarmTypeArray();
    for (let i = 0; i < typeArray.length; i++) {
        if (typeArray[i].value === type) {
            return typeArray[i].label;
        }
    }
    return '--';
}
export function getTerminalStatus(terminalTypeDisplay, terminalStatusDetail) {
    const terminalAction = {
        0: {
            tagType: 'danger',
            label: '开门报警',
        },
        1: {
            tagType: 'danger',
            label: '拆除报警',
        },
        2: {
            tagType: 'warning',
            label: '低电压故障',
        },
        3: {
            tagType: 'warning',
            label: '紧急报警',
        }
    };
    if (terminalTypeDisplay === 1 || terminalTypeDisplay === 2) {
        return terminalAction[terminalStatusDetail];
    }
    else {
        return getTerminalTypeDisplay(terminalTypeDisplay);
    }
}
export function getTerminalTypeDisplay(type = 'default') {
    // 0:未激活 1：报警 2：故障 3：离线 4：停用 5：正常
    const terminalAction = {
        0: {
            tagType: 'info',
            label: '未激活',
        },
        1: {
            tagType: 'danger',
            label: '报警',
        },
        2: {
            tagType: 'warning',
            label: '故障',
        },
        3: {
            tagType: 'warning',
            label: '离线'
        },
        4: {
            tagType: 'info',
            label: '停用'
        },
        5: {
            tagType: 'success',
            label: '正常'
        },
        default: {
            tagType: 'info',
            label: '未激活'
        },
    };
    return terminalAction[type] || terminalAction['default'];
}
export function getQrRule(terminalModel, terminalNo) {
    return terminalModel + '-' + terminalNo;
}
export function getAlarmButtonListArray() {
    return [
        { label: '测温', value: 1, color: '#E6A23C' },
        { label: '送药', value: 2, color: '#67C23A' },
        { label: '送物资', value: 3, color: '#04a0f1' },
        { label: '收垃圾', value: 4, color: '#31bbc7' },
        { label: '测试', value: 5, color: '#909399' },
        { label: '报警', value: 6, color: '#e73a03' },
        { label: '其他', value: 7, color: '#8A6AE6' },
    ];
}
export function getAlarmTypeItem(value) {
    let buttonList = getAlarmButtonListArray();
    for (let i = 0; i < buttonList.length; i++) {
        if (buttonList[i].value === value) {
            return buttonList[i];
        }
    }
    return { label: '-', color: '#8A6AE6' };
}
