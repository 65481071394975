import { useMessage } from "@/common/hooks/web/useMessage";
import { useDebounce } from "@/common/hooks/core/useDebounce";
import { useEventListener } from "@/common/hooks/event/useEventListener";
import { useGo } from "@/common/hooks/web/usePage";
import GPS from "@/common/js/GPS";
import { carUtil } from "@/common/js/carUtil";
import * as is from "@/common/utils/is";
import * as common from "@/common/utils/index";
// import { useECharts } from "./pieChart";
import bsjLocalStorage from "@/common/class/localStorage";
import { getLocalStorage, setLocalStorage, removeLocalStorage } from "@/common/utils/globalLocalStorage";
export default function useCommon() {
    return {
        bsjLocalStorage,
        common,
        is,
        useDebounce,
        useMessage,
        useEventListener,
        useGo,
        getLocalStorage,
        setLocalStorage,
        removeLocalStorage,
        carUtil,
        GPS
    };
}
