import { defineComponent } from 'vue';
import { useStore } from 'vuex';
import AppLogo from '@/components/AppLogo/index.vue';
import Menu from '../menu/';
export default defineComponent({
  name: 'AppSider',
  components: {
    AppLogo: AppLogo,
    Menu: Menu
  },
  setup: function setup() {
    var _useStore = useStore(),
        getters = _useStore.getters;

    return {};
  }
});