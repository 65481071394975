import { defineComponent } from 'vue';
import '@/assets/iconfont/iconfont.css';
export default defineComponent({
  name: 'Iconfont',
  components: {},
  props: {
    name: {
      type: String
    }
  },
  setup: function setup() {
    return {};
  }
});