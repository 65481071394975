import store from '@/store';
import API from "@/api/httpApi";
import { createRouter, createWebHistory } from 'vue-router';
import { basicRoutes } from './routes/';
import { hasAuth } from '@/utils/auth';
const LOGIN_PATH = '/login';
const router = createRouter({
    history: createWebHistory(),
    routes: basicRoutes
});
router.beforeEach(async (from, to, next) => {
    const { state, commit } = store;
    // 不需要登录,可访问
    if (from.meta.ignoreAuth === true) {
        next();
        return;
    }
    ;
    // 如果url 上携带 sessionId 则直接登录
    if (window.location.href.indexOf('?sessionId=') !== -1) {
        let sessionId = window.location.href.split('?sessionId=')[1];
        let urlData = window.location.href.split('?sessionId=')[0];
        // 清空 sessionId
        window.localStorage.clear();
        // 写入 sessionId
        store.commit('COMMIT_SESSIONID', sessionId);
        // 写入 user 信息
        const userData = urlData.split('?data=')[1];
        const codeData = decodeURIComponent(userData);
        store.commit('COMMIT_USER', JSON.parse(codeData));
        // 获取公司信息
        try {
            const res = await API.getAccountOrgDetail({});
            // 写入缓存
            store.commit('COMMIT_ACCOUNTORGDETAIL', res.data);
            if (res.data && res.data.logoImage) {
                const str = window.location.href;
                const count = str.indexOf('/login');
                let urlData = str.substr(0, count - 1);
                if (str.indexOf(':808') !== -1) {
                    urlData = urlData + '4';
                }
                else {
                    urlData = str.substr(0, count) + ':8085';
                }
                store.commit('COMMIT_personalAvatarImg', `${urlData}/${res.data.logoImage}`);
            }
        }
        catch (err) {
            console.log(err);
            throw (err.msg ? err.msg : err);
        }
        window.open(urlData.split('?data=')[0], '_self');
        return;
    }
    // 处理token
    const hasToken = !!state.user.sessionId;
    if (!hasToken) { // 未登陆，或过期
        // 重定向到登录页
        const redirectData = {
            path: LOGIN_PATH,
            replace: true,
        };
        next(redirectData);
        return;
    }
    // 存储缓存页面
    const hasAuthority = from.meta.id && hasAuth(from.meta.id);
    if (hasAuthority) {
        if (from.meta.pageCache) {
            store.commit('setPageCache', {
                name: from.name,
                title: from.meta.title,
                path: from.path
            });
        }
    }
    next();
});
export default router;
