import { createStore } from 'vuex';
import { getLSObj } from '@/utils';
import user from "./modules/user";
import main from "@/common/store/main";
import { setLocal } from '@/utils/cache';
const LSObj = getLSObj();
export default createStore({
    modules: {
        user,
        main,
    },
    state: {
        pageCaches: [],
    },
    mutations: {
        COMMIT_CLEAR(state) {
        },
        // 缓存页面保存
        setPageCache(state, data) {
            if (!state.pageCaches.some((item) => item.name == data.name)) {
                state.pageCaches = [...state.pageCaches, data];
            }
            setLocal('pageCaches', state.pageCaches);
        },
    },
    actions: {}
});
