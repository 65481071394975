import { isNull } from 'lodash-es';
import localCache from './localCache';
import sessionCache from './sessionCache';
const prefix = 'BSJ';
const version = process.env.VUE_APP_BUILD_VERSION;
function cacheName(name) {
    return `__${prefix}_${version}_${name}`;
}
export function setLocal(keyName, keyValue, expire) {
    return localCache.setItem(cacheName(keyName), keyValue, expire);
}
export function getLocal(keyName) {
    const cache = localCache.getItem(cacheName(keyName));
    if (isNull(cache))
        return null;
    return cache.value;
}
export function removeLocal(keyName) {
    localCache.removeItem(cacheName(keyName));
}
export function setSession(keyName, keyValue, expire) {
    return sessionCache.setItem(cacheName(keyName), keyValue, expire);
}
export function getSession(keyName) {
    const cache = sessionCache.getItem(cacheName(keyName));
    if (isNull(cache))
        return null;
    return cache.value;
}
export function removeSession(keyName) {
    sessionCache.removeItem(cacheName(keyName));
}
