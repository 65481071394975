import { defineComponent } from 'vue';
import { ElMain } from 'element-plus';
import PageLayout from '../page';
export default defineComponent({
  name: 'AppContent',
  components: {
    ElMain: ElMain,
    PageLayout: PageLayout
  },
  setup: function setup() {
    return {};
  }
});