import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_PageLayout = _resolveComponent("PageLayout");

  var _component_el_main = _resolveComponent("el-main");

  return _openBlock(), _createBlock(_component_el_main, {
    class: "PageContent"
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_PageLayout)];
    }),
    _: 1
  });
}