export const requireColumns = [
    {
        title: '被访人名称',
        key: 'name',
        id: 1,
        visible: true,
    },
    {
        title: '被访人手机',
        key: 'phone',
        id: 2,
        visible: true,
    },
    {
        title: '访问时间段',
        key: 'time',
        id: 3,
        visible: true,
    },
    {
        title: '来访人',
        key: 'userName',
        id: 4,
        visible: true,
    },
    {
        title: '来访人手机号',
        key: 'userPhone',
        id: 5,
        visible: true,
    },
];
export const optionColumns = [
    {
        title: '访问地点',
        key: 'installAddress',
        id: 1,
        visible: false,
    },
    {
        title: '来访人数',
        key: 'personNum',
        id: 2,
        visible: false,
    },
    {
        title: '来访地区（市区）',
        key: 'visitingAreas',
        id: 3,
        visible: false,
    },
    {
        title: '来访原因',
        key: 'visitingReason',
        id: 4,
        visible: false,
    },
    {
        title: '健康码状态',
        key: 'healthCodeState',
        id: 5,
        visible: true,
    },
    {
        title: '车牌号',
        key: 'plateNumber',
        id: 6,
        visible: true,
    },
    {
        title: '车辆是否入园',
        key: 'vehicle',
        id: 7,
        visible: true,
    },
];
