// 旧版接口
const beforeUrl = {
    // login: "/user/login.json", // 登录
    /*
  
      账户实体类
  
    */
    login: "/account/login",
    sendNote: "/account/sendNote",
    register: "/account/register",
    outLogin: "/account/outLogin",
    updateUserPassword: "/account/updateUserPassword",
    retrievePassword: "/account/retrievePassword",
    retrievePasswordSendNote: "/account/retrievePasswordSendNote",
    /*
  
      公司信息实体类
  
    */
    insertCompanyInfo: "/accountOrgDetail/insertCompanyInfo",
    getAccountOrgDetail: "/accountOrgDetail/getAccountOrgDetail",
    updateCompanyInfo: "/accountOrgDetail/updateCompanyInfo",
    /*
    
      组织控制层
    
    */
    addOrganization: "/organization/addOrganization",
    deleteOrganization: "/organization/deleteOrganization",
    getOrgInfo: "/organization/getOrgInfo",
    getOrgTree: "/organization/getOrgTree",
    getOrgUser: "/organization/getOrgUser",
    updateOrgName: "/organization/updateOrgName",
    getBindOrg: "/organization/getBindOrg",
    getStructure: '/organization/getStructure',
    /*
    
      用户控制层
  
    */
    addUser: "/user/addUser",
    deleteCompanyUser: "/user/deleteCompanyUser",
    updateUserInfo: "/user/updateUserInfo",
    getInUserInfo: '/user/getInUserInfo',
    getBindUserInfo: '/user/getBindUserInfo',
    existInviteRecord: '/user/existInviteRecord',
    /*
    
      设备管理
    
    */
    addDevice: "/device/addDevice",
    updateDevice: "/device/updateDevice",
    deleteDevice: "/device/deleteDevice",
    authDeviceBind: "/device/authDeviceBind",
    getAllDevice: "/device/getAllDevice",
    getDeviceInfo: "/device/getDeviceInfo",
    getDeviceBind: "/device/getDeviceBind",
    getDeviceBindAndPermission: "/device/getDeviceBindAndPermission",
    existDeviceBind: "/device/existDeviceBind",
    /*
    
      开锁历史记录
    
    */
    getHistoryOpenDoorRecord: "/openDoor/getHistoryOpenDoorRecord",
    deleteHistoryOpenDoorRecord: "/openDoor/deleteHistoryOpenDoorRecord",
    exportHistoryOpenDoorRecord: "/openDoor/exportHistoryOpenDoorRecord",
    /*
   
     访客预约单
   
   */
    getAllVisitorOrder: "/visitor/getAllVisitorOrder",
    updateVisitorOrderStatus: "/visitor/updateVisitorOrderStatus",
    deleteVisitorOrder: "/visitor/deleteVisitorOrder",
    /*
   
      邀请访客单记录
   
    */
    getAllInviteOrder: "/invite/getAllInviteOrder",
    deleteInviteOrder: "/invite/deleteInviteOrder",
    getInviteOrderDetail: "/invite/getInviteOrderDetail",
    /*
   
      首页控制层
   
    */
    statisticalNumber: "/home/statisticalNumber",
    getTopOrg: "/home/getTopOrg",
    getStructureV2: "/organization/getStructureV2",
    getOnlineDevice: "/device/getOnlineDevice",
    /*
     
      系统变量设置
     
    */
    addSetting: '/setting/addSetting',
    // updateSetting: "/setting/updateSetting", // 修改系统变量
    getSetting: "/setting/getSetting", // 获取系统变量
};
export default beforeUrl;
