import { defineComponent, computed } from 'vue';
import { useStore } from 'vuex';
import Avatar from '@/components/LoginAvatar/index.vue';
import SetUp from '@/components/SetUp/index.vue';
import { useRoute, useRouter } from 'vue-router';
export default defineComponent({
  name: 'AppHeader',
  components: {
    Avatar: Avatar,
    SetUp: SetUp
  },
  props: {
    setTopOrgId: {
      type: Boolean,
      default: false
    }
  },
  setup: function setup(props) {
    var route = useRoute();
    var router = useRouter();

    var _useStore = useStore(),
        getters = _useStore.getters,
        commit = _useStore.commit,
        dispatch = _useStore.dispatch;

    var navTitle = computed(function () {
      return '小博考勤';
    });
    return {
      navTitle: navTitle
    };
  }
});