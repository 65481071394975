import QS from "qs";
import axios from "./apiConfig";
import useCommon from "@/utils/common";
import axiosJsonp from "axios-jsonp";
import store from "@/store";
import { getBaseUrl } from "@/utils";
const { GPS } = useCommon();
// get 所有数据过滤 前后空格
function clearDataTrim(data) {
    if (!data || Object.keys(data).length == 0) {
        return data;
    }
    for (let key in data) {
        if (typeof data[key] === 'string') {
            data[key] = data[key].trim();
        }
    }
    return data;
}
// 替换Url参数
export const replaceUrl = (url, params) => {
    Object.keys(params).forEach((item) => {
        url = url.replace(new RegExp(`{${item}}`), params[item]);
    });
    return url;
};
const handleApiFn = (data) => {
    if (data.code === undefined) {
        return Promise.resolve(data);
    }
    if (data.code === 0) {
        return Promise.resolve(data);
    }
    return Promise.reject(data);
};
const handleLbsMulti = (msg) => {
    return Promise.resolve(msg);
};
const handleReject = (err, url = '') => {
    try {
        let msg = '网络异常';
        if (err.status && err.data.msg) {
            msg = err.data.msg;
        }
        return Promise.reject({ msg });
    }
    catch (error) {
        console.log(error);
    }
};
export async function GET(url, params) {
    try {
        // 过滤空格
        let clearTrim = clearDataTrim(params);
        const { data } = await axios.get(url, { params: clearTrim });
        return handleApiFn(data);
    }
    catch (error) {
        console.log(error);
        return handleReject(error, url);
    }
}
export async function POST(url, params) {
    try {
        // 过滤空格
        let clearTrim = clearDataTrim(params);
        const { data } = await axios.post(url, QS.stringify(clearTrim));
        // const { data }  = await axios.post(url, params);
        return handleApiFn(data);
    }
    catch (error) {
        return handleReject(error);
    }
}
export async function POSTBody(url, params) {
    try {
        const { data } = await axios({
            url,
            data: clearDataTrim(params),
            method: "post",
            headers: {
                "Content-Type": "application/json"
            }
        });
        return handleApiFn(data);
    }
    catch (error) {
        return handleReject(error);
    }
}
export async function POSTForm(url, params) {
    try {
        const { data } = await axios({
            url,
            data: clearDataTrim(params),
            method: "post",
            headers: {
                "Content-Type": "application/json;charset=UTF-8"
            }
        });
        return handleApiFn(data);
    }
    catch (error) {
        return handleReject(error);
    }
}
export async function POSTExport(url, params) {
    try {
        const { data } = await axios({
            url,
            data: clearDataTrim(params),
            method: "post",
            headers: {
                "Content-Type": "application/json;charset=UTF-8"
            },
            responseType: "blob"
        });
        return handleApiFn(data);
    }
    catch (error) {
        return handleReject(error);
    }
}
export async function FORM(url, params) {
    try {
        const formData = new window.FormData();
        if (params) {
            Object.keys(params).forEach((key) => {
                const value = params[key];
                formData.append(key, value);
            });
        }
        const { data } = await axios({
            url,
            data: formData,
            method: "post",
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });
        return handleApiFn(data);
    }
    catch (error) {
        return handleReject(error);
    }
}
export async function FORMPost(url, params) {
    try {
        const formData = new window.FormData();
        if (params) {
            Object.keys(params).forEach((key) => {
                const value = params[key];
                formData.append(key, value);
            });
        }
        const { data } = await axios({
            url,
            data: formData,
            method: "post",
            headers: {
                "Content-Type": "application/json;charset=UTF-8"
            }
        });
        return handleApiFn(data);
    }
    catch (error) {
        return handleReject(error);
    }
}
export async function UPLOADFILE(url, params) {
    try {
        const { data } = await axios({
            url,
            data: params,
            method: "post"
        });
        return handleApiFn(data);
    }
    catch (error) {
        return handleReject(error);
    }
}
export async function JSONP(url, params) {
    try {
        const data = await axios({
            adapter: axiosJsonp,
            url,
            params,
            callbackParamName: 'jsoncallback',
            method: "get"
        });
        return handleApiFn(data.data);
    }
    catch (err) {
        return handleReject(err);
    }
}
export async function lbsMultiFn(url, params) {
    try {
        const data = await axios({
            adapter: axiosJsonp,
            url,
            params,
            callbackParamName: 'jsoncallback',
            method: "get"
        });
        return handleLbsMulti(data.data);
    }
    catch (err) {
        return handleLbsMulti(err);
    }
}
// export function exportFile(json: string, params: object){
//   axios({
//     method: 'GET',
//     url: `http://47.106.47.148:9999/car/v1/api/renew/exportVehicleExpire.json?offlineTimeBegin=0&offlineTimeEnd=0&isState=&vehicleState=&installDateStart=&installDateEnd=&groupId=&vehicleId=&activationTmeStart=&activationTmeEnd=&displayYear=0&expireTime=2678400&pageNumber=1&pageSize=40&sessionId=2b7ad8caf6144883a4b5670ffc9de9f7`,
//     responseType: 'blob'
//   }).then((res) => {
//       const link = document.createElement('a')
//       let blob = new Blob([res.data], {type: 'application/vnd.ms-excel'})
//       link.style.display
//        = 'none'
//       link.href = URL.createObjectURL(blob);
//       console.log(res);
//       link.download = '下载名称';
//       document.body.appendChild(link);
//       link.click();
//       document.body.removeChild(link);
//   }).catch(error => {
//       console.log(error);
//   })
// }
export async function ElUpload(url, params, fileObj) {
    try {
        const formData = new window.FormData();
        if (params) {
            Object.keys(params).forEach((key) => {
                const value = params[key];
                formData.append(key, value);
            });
        }
        const { data } = await axios({
            url,
            data: formData,
            method: "post",
            headers: {
                "Content-Type": "multipart/form-data"
            },
            onUploadProgress: progressEvent => {
                const complete = (progressEvent.loaded / progressEvent.total * 100 | 0);
                fileObj.onProgress({ percent: complete });
            }
        });
        return handleApiFn(data);
    }
    catch (error) {
        return handleReject(error);
    }
}
/**
 * @desc   下载文件
 * @param  {String} baseUrl
 * @param  {Object} params
 */
export function exportFile(json, params) {
    // 处理baseUrl
    const baseUrl = getBaseUrl();
    const lastIndex = baseUrl.length - 1;
    const newBaseUrl = baseUrl[lastIndex] === "/" ? baseUrl.slice(0, lastIndex) : baseUrl;
    // 处理json
    const newJson = json[0] === "/" ? json.slice(1) : json;
    let arr = [];
    // 处理参数
    Object.entries(params).map(([key, val]) => {
        if (val !== null) {
            arr.push(`${key}=${val}`);
        }
    });
    // 添加session_id
    arr.push("sessionId=" + store.state.main.sessionId);
    // 生成url
    let url = `${newBaseUrl}/${newJson}?${arr.join("&")}`;
    if (newJson.slice(0, 4) === "http") {
        url = newJson;
    }
    const elemIF = document.createElement("iframe");
    elemIF.src = url;
    elemIF.style.display = "none";
    document.body.appendChild(elemIF);
}
// 请求地址hash缓存
const addressHash = new Map();
/**
 * @desc 将请求数组切割成50一份 分批获取地址
 * @param {Array} list
 * @return {Promise}
 */
export async function GetGeo(LocationList, 
/**
 * 是否需要纠偏
 */
change = true) {
    // 缓存过多则清除防止溢出
    if (addressHash.size > 1000) {
        addressHash.clear();
    }
    // 下标与经纬度的映射
    const tagToLonLat = new Map();
    const canGetLocals = LocationList.reduce((acc, item, index) => {
        if (addressHash.has(`${item.lon}_${item.lat}`))
            return acc;
        let lon = item.lon;
        let lat = item.lat;
        if (change) {
            const res = GPS.gcj_encrypt(item.lat, item.lon);
            lon = res.lon;
            lat = res.lat;
        }
        const tag = item.tag === undefined ? index : item.tag;
        tagToLonLat.set(tag, `${item.lon}_${item.lat}`);
        return acc.concat([
            {
                lon,
                lat,
                tag
            }
        ]);
    }, []);
    const chunks = []; // 数组切割成50一组的数组
    for (let i = 0; i < canGetLocals.length; i += 50) {
        chunks.push(canGetLocals.slice(i, i + 50));
    }
    if (chunks.length) {
        const result = await Promise.all(chunks.map(chunk => getLocal(chunk)));
        result
            .reduce((acc, item) => acc.concat(item))
            .forEach(({ tag, address }) => {
            const lonLatKey = tagToLonLat.get(Number(tag));
            addressHash.set(lonLatKey, address);
        });
    }
    return LocationList.map((item, index) => {
        const tag = item.tag === undefined ? index : item.tag;
        return {
            tag,
            address: addressHash.get(`${item.lon}_${item.lat}`)
        };
    });
}
/**
 * @desc 获取地址 {lat,lon,tag}  经,纬,下标
 * @param {Array} list
 * @return {Promise}
 */
async function getLocal(posList) {
    try {
        const { obj } = await JSONP("https://lbsserver.car900.com/geo/GetGeo.json", {
            param: JSON.stringify({ posList })
        });
        if (obj != null && obj.length > 0) {
            const addressList = [];
            obj.map((item) => {
                if (item.regeocode) {
                    let address = "";
                    if (item.regeocode.roads.length > 0) {
                        address = formatDisplayRow(item);
                        // const road = item.regeocode.roads[0];
                        // address = `${item.regeocode.formatted_address}(在${road.name}的${road.direction
                        //   }方向约${parseInt(road.distance)}米)`;
                    }
                    else {
                        address = item.regeocode.formatted_address;
                    }
                    addressList.push({
                        address,
                        tag: item.tag
                    });
                }
                else {
                    addressList.push({
                        address: "获取位置失败",
                        tag: item.tag
                    });
                }
            });
            return Promise.resolve(addressList.map(item => ({
                ...item,
                address: item.address === "[]" ? "获取位置失败" : item.address
            })));
        }
        const addressList = posList.map((item) => ({
            ...item,
            address: "获取位置失败"
        }));
        return Promise.resolve(addressList);
    }
    catch (error) {
        console.error(error);
        const addressList = posList.map((item) => ({
            ...item,
            address: "获取位置失败"
        }));
        return Promise.resolve(addressList);
    }
}
/**
 * 格式化显示地址格式
 */
function formatDisplayRow(itemData) {
    let result = "";
    if (itemData.regeocode) {
        result = itemData.regeocode.formatted_address;
        itemData.regeocode.roads.map((roadItem, roadIndex) => {
            if (roadIndex === 0) {
                result += " (";
            }
            else {
                result += " ";
            }
            result +=
                "在" +
                    roadItem.name +
                    "的" +
                    roadItem.direction +
                    "方向约" +
                    Math.floor(roadItem.distance) +
                    "米";
            if (roadIndex === itemData.regeocode.roads.length - 1) {
                result += ")";
            }
        });
    }
    else {
        result = "获取地址失败";
    }
    return result;
}
