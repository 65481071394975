import "../../common/qiankun/public-path";
import actions from "../../common/qiankun/actions";
import { createApp } from 'vue';
import router from './router';
import store from './store';
import App from './App.vue';
import 'element-plus/dist/index.css';
import('@/assets/css/reset.less');
// import { digitalFlop } from '@jiaminghi/data-view';
import registerGlobComp from '@/components/registerGlobComp';
// 权限判断
import { setupDirectives } from './directives';
let instance;
// 渲染方法
function render(props) {
    const { container } = props;
    instance = createApp(App);
    // .use(digitalFlop)
    instance
        .use(store)
        .use(router)
        .mount(container instanceof Element
        ? container.querySelector("#app")
        : container);
    // 注册权限指令
    setupDirectives(instance);
    // 注册图标指令
    registerGlobComp(instance);
}
// import ('@/assets/css/resetLocation.less');
// 独立运行时
if (!window.__POWERED_BY_QIANKUN__) {
    console.log('独立运行');
    render({ container: "#app" });
}
export async function bootstrap() {
    console.log("subapp bootstraped");
}
export async function mount(props) {
    actions.setActions(props);
    render(props);
}
export async function unmount(props) {
    instance.unmount();
}
