function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import { defineComponent, computed } from 'vue';
import Iconfont from './Iconfont.vue';
import IconEl, { isElement } from './IconEl.vue';
import IconAnt, { isAnt } from './IconAnt.vue';
export default defineComponent({
  name: 'Icon',
  components: {
    Iconfont: Iconfont,
    IconEl: IconEl,
    IconAnt: IconAnt
  },
  props: {
    name: {
      type: String,
      required: true
    },
    size: {
      type: String,
      default: '16'
    }
  },
  setup: function setup(props, _ref) {
    var attrs = _ref.attrs;
    var iconComp = computed(function () {
      // name可能是unknown类型
      if (isElement(props.name)) return 'IconEl';
      if (isAnt(props.name)) return 'IconAnt';
      return Iconfont;
    });
    var bindProps = computed(function () {
      return _objectSpread(_objectSpread({}, props || {}), attrs || {});
    });
    return {
      iconComp: iconComp,
      bindProps: bindProps
    };
  }
});