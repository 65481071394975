import { getLSMainObj } from "../utils";
const LSObj = getLSMainObj();
const main = {
    state: {
        sessionId: LSObj.getLocalStorage("sessionId") ? JSON.parse(LSObj.getLocalStorage("sessionId")) : null,
        USER: LSObj.getLocalStorage("USER") ? JSON.parse(LSObj.getLocalStorage("USER")) : null,
        PLATEINFO: LSObj.getLocalStorage("PLATEINFO") ? JSON.parse(LSObj.getLocalStorage("PLATEINFO")) : {
            homePageName: '',
        },
    },
    mutations: {
        COMMIT_SESSIONID(state, val) {
            val ? LSObj.setLocalStorage("sessionId", val) : LSObj.removeLocalStorage("sessionId");
            state.sessionId = val;
        },
        COMMIT_PLATEINFO(state, val) {
            val ? LSObj.setLocalStorage("PLATEINFO", val) : LSObj.removeLocalStorage("PLATEINFO");
            state.PLATEINFO = val;
        },
    },
    actions: {}
};
export default main;
