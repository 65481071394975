import { ArrowDownBold, ArrowLeftBold, ArrowRightBold, ArrowUpBold, Avatar, Bell, Brush, CaretBottom, CaretTop, Cellphone, Check, Checked, CircleCheck, CircleCloseFilled, CircleClose, CirclePlusFilled, CirclePlus, CloseBold, Close, Crop, DeleteFilled, Delete, DocumentRemove, Download, EditPen, ElemeFilled, Folder, FullScreen, HomeFilled, InfoFilled, LocationFilled, Location, Message, More, Operation, Opportunity, Plus, QuestionFilled, Refresh, Remove, Right, Search, Setting, Share, SwitchButton, Switch, UserFilled, User, WarningFilled, Warning } from "@element-plus/icons-vue";
export const isElementIcon = {
    // AddLocation,
    // Aim,
    // AlarmClock,
    // Apple,
    ArrowDownBold,
    // ArrowDown,
    ArrowLeftBold,
    // ArrowLeft,
    ArrowRightBold,
    // ArrowRight,
    ArrowUpBold,
    // ArrowUp,
    Avatar,
    // Back,
    // Baseball,
    // Basketball,
    // BellFilled,
    Bell,
    // Bicycle,
    // BottomLeft,
    // BottomRight,
    // Bottom,
    // Bowl,
    // Box,
    // Briefcase,
    // BrushFilled,
    Brush,
    // Burger,
    // Calendar,
    // CameraFilled,
    // Camera,
    CaretBottom,
    // CaretLeft,
    // CaretRight,
    CaretTop,
    Cellphone,
    // ChatDotRound,
    // ChatDotSquare,
    // ChatLineRound,
    // ChatLineSquare,
    // ChatRound,
    // ChatSquare,
    Check,
    Checked,
    // Cherry,
    // Chicken,
    // CircleCheckFilled,
    CircleCheck,
    CircleCloseFilled,
    CircleClose,
    CirclePlusFilled,
    CirclePlus,
    // Clock,
    CloseBold,
    Close,
    // Cloudy,
    // CoffeeCup,
    // Coffee,
    // Coin,
    // ColdDrink,
    // CollectionTag,
    // Collection,
    // Comment,
    // Compass,
    // Connection,
    // Coordinate,
    // CopyDocument,
    // Cpu,
    // CreditCard,
    Crop,
    // DArrowLeft,
    // DArrowRight,
    // DCaret,
    // DataAnalysis,
    // DataBoard,
    // DataLine,
    DeleteFilled,
    // DeleteLocation,
    Delete,
    // Dessert,
    // Discount,
    // DishDot,
    // Dish,
    // DocumentAdd,
    // DocumentChecked,
    // DocumentCopy,
    // DocumentDelete,
    DocumentRemove,
    // Document,
    Download,
    // Drizzling,
    EditPen,
    // Edit,
    ElemeFilled,
    // Eleme,
    // Expand,
    // Failed,
    // Female,
    // Files,
    // Film,
    // Filter,
    // Finished,
    // FirstAidKit,
    // Flag,
    // Fold,
    // FolderAdd,
    // FolderChecked,
    // FolderDelete,
    // FolderOpened,
    // FolderRemove,
    Folder,
    // Food,
    // Football,
    // ForkSpoon,
    // Fries,
    FullScreen,
    // GobletFull,
    // GobletSquareFull,
    // GobletSquare,
    // Goblet,
    // GoodsFilled,
    // Goods,
    // Grape,
    // Grid,
    // Guide,
    // Headset,
    // HelpFilled,
    // Help,
    // Histogram,
    HomeFilled,
    // HotWater,
    // House,
    // IceCreamRound,
    // IceCreamSquare,
    // IceCream,
    // IceDrink,
    // IceTea,
    InfoFilled,
    // Iphone,
    // Key,
    // KnifeFork,
    // Lightning,
    // Link,
    // List,
    // Loading,
    LocationFilled,
    // LocationInformation,
    Location,
    // Lock,
    // Lollipop,
    // MagicStick,
    // Magnet,
    // Male,
    // Management,
    // MapLocation,
    // Medal,
    // Menu,
    // MessageBox,
    Message,
    // Mic,
    // Microphone,
    // MilkTea,
    // Minus,
    // Money,
    // Monitor,
    // MoonNight,
    // Moon,
    // MoreFilled,
    More,
    // MostlyCloudy,
    // Mouse,
    // Mug,
    // MuteNotification,
    // Mute,
    // NoSmoking,
    // Notebook,
    // Notification,
    // Odometer,
    // OfficeBuilding,
    // Open,
    Operation,
    Opportunity,
    // Orange,
    // Paperclip,
    // PartlyCloudy,
    // Pear,
    // PhoneFilled,
    // Phone,
    // PictureFilled,
    // PictureRounded,
    // Picture,
    // PieChart,
    // Place,
    // Platform,
    Plus,
    // Pointer,
    // Position,
    // Postcard,
    // Pouring,
    // Present,
    // PriceTag,
    // Printer,
    // Promotion,
    QuestionFilled,
    // Rank,
    // ReadingLamp,
    // Reading,
    // RefreshLeft,
    // RefreshRight,
    Refresh,
    // Refrigerator,
    // RemoveFilled,
    Remove,
    Right,
    // ScaleToOriginal,
    // School,
    // Scissor,
    Search,
    // Select,
    // Sell,
    // SemiSelect,
    // Service,
    // SetUp,
    Setting,
    Share,
    // Ship,
    // Shop,
    // ShoppingBag,
    // ShoppingCartFull,
    // ShoppingCart,
    // Smoking,
    // Soccer,
    // SoldOut,
    // SortDown,
    // SortUp,
    // Sort,
    // Stamp,
    // StarFilled,
    // Star,
    // Stopwatch,
    // SuccessFilled,
    // Sugar,
    // Suitcase,
    // Sunny,
    // Sunrise,
    // Sunset,
    SwitchButton,
    Switch,
    // TakeawayBox,
    // Ticket,
    // Tickets,
    // Timer,
    // ToiletPaper,
    // Tools,
    // TopLeft,
    // TopRight,
    // Top,
    // TrendCharts,
    // Trophy,
    // TurnOff,
    // Umbrella,
    // Unlock,
    // UploadFilled,
    // Upload,
    UserFilled,
    User,
    // Van,
    // VideoCameraFilled,
    // VideoCamera,
    // VideoPause,
    // VideoPlay,
    // View,
    // WalletFilled,
    // Wallet,
    WarningFilled,
    Warning,
    // Watch,
    // Watermelon,
    // WindPower,
    // ZoomIn,
    // ZoomOut 
};
