import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, KeepAlive as _KeepAlive, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_RouterView = _resolveComponent("RouterView");

  return _openBlock(), _createBlock(_component_RouterView, null, {
    default: _withCtx(function (_ref) {
      var Component = _ref.Component,
          route = _ref.route;
      return [(_openBlock(), _createBlock(_KeepAlive, {
        include: _ctx.pageCaches,
        max: _ctx.keepAliveMax
      }, [(_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
        key: route.path
      }))], 1032, ["include", "max"]))];
    }),
    _: 1
  });
}