import Layout from "@/layout/index";
//生成重定向页面
function redirectUrl() {
    return "/home/index";
}
// 主框架根路由
export const LoginRoute = [
    {
        path: '/dom',
        name: 'dom',
        component: () => import('@/views/dom.vue'),
        meta: {
            title: '测试',
            ignoreAuth: true,
        },
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('@/views/login/Login.vue'),
        meta: {
            title: '登录',
            ignoreAuth: true,
        },
    },
    {
        path: "/",
        name: "home",
        component: Layout,
        redirect: '/home/index',
        meta: {
            icon: 'icon-a-icon_shouye1',
            title: '首页',
            ignoreAuth: false,
            pageCache: true,
            id: 10
        },
        children: [
            {
                path: '/home/index',
                name: 'homeIndex',
                component: () => import('@/views/home/index.vue'),
                meta: {
                    icon: 'icon-a-icon_shouye1',
                    title: '首页',
                    ignoreAuth: false,
                    pageCache: true,
                    id: 1001
                },
            },
        ]
    },
    {
        path: "/deviceManag",
        name: "deviceManag",
        component: Layout,
        redirect: '/deviceManag/deviceManag',
        meta: {
            icon: 'icon-icon_shebei',
            title: '设备管理',
            ignoreAuth: false,
            pageCache: true,
            id: 10
        },
        children: [
            {
                path: '/deviceManag/deviceManag',
                name: 'deviceManag',
                component: () => import('@/views/deviceManag/deviceManag.vue'),
                meta: {
                    icon: 'icon-icon_shebei',
                    title: '设备管理',
                    ignoreAuth: false,
                    pageCache: true,
                    id: 1001
                },
            },
        ]
    },
    {
        path: "/organization",
        name: "organization",
        component: Layout,
        redirect: '/organization/organization',
        meta: {
            icon: 'icon-icon_yuangong',
            title: '组织架构',
            ignoreAuth: false,
            pageCache: true,
            id: 10
        },
        children: [
            {
                path: '/organization/organization',
                name: 'organization',
                component: () => import('@/views/organization/organization.vue'),
                meta: {
                    icon: 'icon-icon_yuangong',
                    title: '组织架构',
                    ignoreAuth: false,
                    pageCache: true,
                    id: 1001
                },
            },
        ]
    },
    {
        path: "/reserve",
        name: "reserve",
        component: Layout,
        redirect: '/reserve/reserve',
        meta: {
            icon: 'icon-icon_wendang',
            title: '预约记录',
            ignoreAuth: false,
            pageCache: true,
            id: 10
        },
        children: [
            {
                path: '/reserve/reserve',
                name: 'reserve',
                component: () => import('@/views/reserve/reserve.vue'),
                meta: {
                    icon: 'icon-icon_wendang',
                    title: '预约记录',
                    ignoreAuth: false,
                    pageCache: true,
                    id: 1001
                },
            },
        ]
    },
    {
        path: "/unlockRecord",
        name: "unlockRecord",
        component: Layout,
        redirect: '/unlockRecord/unlockRecord',
        meta: {
            icon: 'icon-lishijilu',
            title: '开锁记录',
            ignoreAuth: false,
            pageCache: true,
            id: 10
        },
        children: [
            {
                path: '/unlockRecord/unlockRecord',
                name: 'unlockRecord',
                component: () => import('@/views/unlockRecord/unlockRecord.vue'),
                meta: {
                    icon: 'icon-lishijilu',
                    title: '开锁记录',
                    ignoreAuth: false,
                    pageCache: true,
                    id: 1001
                },
            },
        ]
    },
    {
        path: '/:pathMatch(.*)*',
        name: '404',
        component: () => import('@/views/404/index.vue'),
        meta: {
            title: '404',
        },
    },
];
// 基础路由
export const basicRoutes = LoginRoute;
