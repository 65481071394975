import dayjs from "dayjs";
import { isNull, isNullOrUnDef } from "../is";
const expireCacheName = '__expire_cache';
export default class CacheStorage {
    storage;
    timerIdMap = new Map();
    constructor(storage) {
        this.storage = storage;
        // 刷新后重新计时
        this.resetExpireKey();
    }
    get length() {
        return this.storage.length;
    }
    key(index) {
        return this.storage.key(index);
    }
    getItem(key) {
        const stringConent = this.storage.getItem(key);
        if (isNullOrUnDef(stringConent))
            return null;
        const content = JSON.parse(stringConent);
        return content;
    }
    setItem(key, value, expire) {
        return new Promise((resolve) => {
            const now = dayjs();
            const content = {
                value,
                time: now.format('YYYY-MM-DD HH:mm:ss.SSS'),
            };
            const hasExpire = !isNullOrUnDef(expire) && expire > 0;
            if (hasExpire) {
                content.expire = now.add(expire, 's').format('YYYY-MM-DD HH:mm:ss.SSS');
                // 缓存需要过期的key
                this.addExpireKey(key, expire, resolve);
            }
            const stringConent = JSON.stringify(content);
            this.storage.setItem(key, stringConent);
        });
    }
    removeItem(key) {
        this.stopTimeout(key);
        return this.storage.removeItem(key);
    }
    stopTimeout(key) {
        if (this.timerIdMap.has(key)) {
            const timerId = this.timerIdMap.get(key);
            this.timerIdMap.delete(key);
            clearTimeout(timerId);
            this.setExpireKey();
        }
    }
    setExpireKey() {
        this.setItem(expireCacheName, [...this.timerIdMap.keys()]);
    }
    addExpireKey(key, expire, callback) {
        this.stopTimeout(key);
        const timerId = setTimeout(() => {
            // console.log('过期:', key);
            this.removeItem(key);
            callback && callback();
        }, expire * 1000);
        this.timerIdMap.set(key, timerId);
        this.setExpireKey();
    }
    resetExpireKey() {
        const content = this.getItem(expireCacheName);
        if (isNull(content))
            return;
        const keys = content.value;
        keys.forEach((cacheName) => {
            const cache = this.getItem(cacheName);
            if (isNull(cache))
                return;
            // 已经过期
            if (dayjs().valueOf() >= dayjs(cache.expire).valueOf()) {
                this.removeItem(cacheName);
            }
            const expire = dayjs(cache.expire).diff(dayjs(), 's');
            this.stopTimeout(cacheName);
            // 没有过期 重新开始计时
            this.setItem(cacheName, cache.value, expire);
        });
    }
}
