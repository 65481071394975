const name = 'main_';
export function getLocalStorage(key) {
    return localStorage.getItem(name + key);
}
export function setLocalStorage(key, val) {
    return localStorage.setItem(name + key, JSON.stringify(val));
}
export function removeLocalStorage(key) {
    return localStorage.removeItem(name + key);
}
