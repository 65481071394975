import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, KeepAlive as _KeepAlive, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_RouterView = _resolveComponent("RouterView");

  var _component_el_config_provider = _resolveComponent("el-config-provider");

  return _openBlock(), _createBlock(_component_el_config_provider, {
    locale: _ctx.elementZh
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_RouterView, null, {
        default: _withCtx(function (_ref) {
          var Component = _ref.Component;
          return [(_openBlock(), _createBlock(_KeepAlive, null, [(_openBlock(), _createBlock(_resolveDynamicComponent(Component)))], 1024))];
        }),
        _: 1
      })];
    }),
    _: 1
  }, 8, ["locale"]);
}