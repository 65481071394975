import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_icon = _resolveComponent("el-icon");

  return _openBlock(), _createBlock(_component_el_icon, null, {
    default: _withCtx(function () {
      return [(_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.name)))];
    }),
    _: 1
  });
}