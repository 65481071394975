import GPS from './GPS';
export class carUtil {
    isWired(carData) {
        if (carData.longStayTer === undefined) {
            console.error("carData.longStayTer为undefined，不能获取设备有线还是无线");
            return true;
        }
        else if (carData.longStayTer === 0) {
            return true;
        }
        else {
            return false;
        }
    }
    getMapStatusTxt(carData, key = "Z") {
        let result = "";
        switch (carData[key]) {
            case 0:
                result = "离线";
                break;
            case 1:
                result = "行驶";
                break;
            case 2:
                result = "停留";
                break;
            case 3:
                result = "从未上线";
                break;
            case 4:
                result = "已过期";
                break;
            default:
                break;
        }
        return result;
    }
    getStopTxt(mongoData, key = "m") {
        var minutes = parseInt((mongoData[key] / 60).toString());
        //累加停留时间
        if (minutes == 0) {
            minutes = minutes == 0 ? "<1分" : minutes + "分";
        }
        else if (minutes > 60) {
            var h = parseInt((minutes / 60).toString());
            var m = minutes % 60;
            if (m == 0) {
                minutes = h + "小时";
            }
            else {
                minutes = h + "小时" + m + "分";
            }
        }
        else {
            minutes = minutes + "分";
        }
        return "静止" + minutes;
    }
    getPos(data, key = 'pos') {
        let result = "";
        switch (data[key]) {
            case 0:
                result = "GPS信号弱";
                break;
            case 1:
                result = "GPS定位";
                break;
            case 2:
                result = "WIFI定位";
                break;
            case 3:
                result = "多基站";
                break; // 多基站
            case 4:
                result = "单基站";
                break; // 单基站
            case 5:
                result = "混合定位";
                break; // 单基站
        }
        return result;
    }
    getAccStateTxt(carData, key = "e") {
        var result = "";
        switch (carData[key]) {
            case 0:
                result = "ACC开";
                break;
            case 1:
                result = "ACC关";
                break;
        }
        ;
        return result;
    }
    getIsStop(data, key = "x") {
        if (data[key] === 2) {
            return true;
        }
        return false;
    }
    getHasNoLocation(mongoData) {
        if (mongoData.extend && mongoData.extend.snp && mongoData.extend.snp.length > 0)
            return true;
        return false;
    }
    getComponentStateTxt(carData) {
        var result = carData.posTxt + " " + carData.accStateTxt;
        if (carData.b === 1) {
            result += " 盲补数据";
        }
        if (carData.power) {
            result += " 电量:" + carData.power + "%";
        }
        return result;
    }
    handlerMongo(mongoData) {
        const result = Object.assign(mongoData, {
            isWired: this.isWired(mongoData),
            statusTxt: this.getMapStatusTxt(mongoData, "x"),
            speed: mongoData["x"] === 1 ? mongoData["s"] : this.getStopTxt(mongoData),
            date: mongoData["t"],
            mileage: mongoData["g"],
            posType: mongoData["i"],
            posTxt: this.getPos(mongoData, "i"),
            angle: mongoData["d"],
            accStateTxt: this.getAccStateTxt(mongoData),
            isStop: this.getIsStop(mongoData, "x"),
            hasNoLocation: this.getHasNoLocation(mongoData),
            position: GPS.defaultToPosition(mongoData.a, mongoData.o),
            componentStateTxt: undefined,
        });
        result.componentStateTxt = this.getComponentStateTxt(result);
        return result;
    }
}
