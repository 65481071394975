import { hasAuth } from '@/utils/auth';
export function setup(app) {
    app.directive('auth', {
        mounted(el, binding) {
            const { value } = binding;
            if (value) {
                if (!hasAuth(value)) {
                    el.parentNode && el.parentNode.removeChild(el);
                }
            }
        },
    });
    app.config.globalProperties.$hasAuth = hasAuth;
}
