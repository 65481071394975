import { defineComponent, computed } from 'vue';
import { useStore } from 'vuex';
export default defineComponent({
  name: 'PageView',
  setup: function setup() {
    var store = useStore();
    var pageCaches = computed(function () {
      return [];
    });
    var keepAliveMax = computed(function () {
      return store.state.keepAliveMax;
    }); // TODO: 缓存需要在验证

    return {
      pageCaches: pageCaches,
      keepAliveMax: keepAliveMax
    };
  }
});