import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_AppHeader = _resolveComponent("AppHeader");

  var _component_AppSiderBar = _resolveComponent("AppSiderBar");

  var _component_AppContent = _resolveComponent("AppContent");

  var _component_el_container = _resolveComponent("el-container");

  return _openBlock(), _createBlock(_component_el_container, {
    class: "appLayout"
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_AppHeader, {
        setTopOrgId: _ctx.setTopOrgId
      }, null, 8, ["setTopOrgId"]), _createVNode(_component_el_container, null, {
        default: _withCtx(function () {
          return [_createVNode(_component_AppSiderBar), _createVNode(_component_AppContent)];
        }),
        _: 1
      })];
    }),
    _: 1
  });
}