import { getLSObj } from "@/utils";
const LSObj = getLSObj();
const user = {
    state: {
        USER: LSObj.getLocalStorage("USER") ? JSON.parse(LSObj.getLocalStorage("USER")) : null,
        USERTREE: [],
        sessionId: LSObj.getLocalStorage("sessionId") ? JSON.parse(LSObj.getLocalStorage("sessionId")) : null,
        permList: [10],
        SETTING: LSObj.getLocalStorage("SETTING") ? JSON.parse(LSObj.getLocalStorage("SETTING")) : null,
        ACCOUNTORGDETAIL: LSObj.getLocalStorage("ACCOUNTORGDETAIL") ? JSON.parse(LSObj.getLocalStorage("ACCOUNTORGDETAIL")) : null,
        personalAvatarImg: LSObj.getLocalStorage("personalAvatarImg") ? JSON.parse(LSObj.getLocalStorage("personalAvatarImg")) : null,
    },
    mutations: {
        // 写入用户信息
        COMMIT_USER(state, val) {
            val ? LSObj.setLocalStorage("USER", val) : LSObj.removeLocalStorage("USER");
            state.USER = val;
        },
        // 清空用户信息
        CLEAR_USER(state) {
            state.USER = null;
            LSObj.removeLocalStorage('USER');
        },
        // 写入用户树
        COMMIT_USERTREE(state, val) {
            state.USERTREE = val;
        },
        // 清空用户树
        CLEAR_USERTREE(state, val) {
        },
        // 写入sessionId
        COMMIT_SESSIONID(state, val) {
            val ? LSObj.setLocalStorage("sessionId", val) : LSObj.removeLocalStorage("sessionId");
            state.sessionId = val;
        },
        // 清空sessinonId
        CLEAR_SESSIONID(state, val) {
            state.sessionId = '';
            LSObj.removeLocalStorage('sessionId');
        },
        // 写入系统变量
        COMMIT_SETTING(state, val) {
            val ? LSObj.setLocalStorage("SETTING", val) : LSObj.removeLocalStorage("SETTING");
            state.SETTING = val;
        },
        // 清空系统变量
        CLEAR_SETTING(state) {
            state.SETTING = null;
            LSObj.removeLocalStorage('SETTING');
        },
        // 写入公司信息
        COMMIT_ACCOUNTORGDETAIL(state, val) {
            val ? LSObj.setLocalStorage("ACCOUNTORGDETAIL", val) : LSObj.removeLocalStorage("ACCOUNTORGDETAIL");
            state.ACCOUNTORGDETAIL = val;
        },
        // 清空公司信息
        CLEAR_ACCOUNTORGDETAIL(state) {
            state.ACCOUNTORGDETAIL = null;
            LSObj.removeLocalStorage('ACCOUNTORGDETAIL');
        },
        // 写入用户头像
        COMMIT_personalAvatarImg(state, val) {
            val ? LSObj.setLocalStorage("personalAvatarImg", val) : LSObj.removeLocalStorage("personalAvatarImg");
            state.personalAvatarImg = val;
        },
        // 清空用户头像
        CLEAR_personalAvatarImg(state) {
            state.personalAvatarImg = null;
            LSObj.removeLocalStorage('personalAvatarImg');
        },
    },
    actions: {
        // 获取新的用户树
        getNewUserTree(store) {
            try {
                const { commit } = store;
                return new Promise(resolve => {
                    // USERAPI.getUserTree({}).then((res:any)=>{
                    //   let treeData = Array.isArray(res.data) ? res.data : [res.data]
                    //   commit('COMMIT_USERTREE',treeData)
                    //   resolve(treeData)
                    // });
                });
            }
            catch (error) {
                console.log(error);
            }
        },
        // 获取用户信息
        getUserInfo(store) {
            return store.state.USER;
        },
        // 获取系统变量
        getSetting(store) {
            return store.state.SETTING;
        },
        // 获取公司信息
        getAccountOrgDetail(store) {
            return store.state.ACCOUNTORGDETAIL;
        },
        // 退出登录
        logout(store) {
            try {
                const { commit } = store;
                // commit('CLEAR_USER');
                commit('CLEAR_SESSIONID');
                commit('CLEAR_SETTING');
                commit('CLEAR_ACCOUNTORGDETAIL');
                commit('CLEAR_personalAvatarImg');
            }
            catch (error) {
                console.log(error);
            }
        }
    }
};
export default user;
