import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_Menu = _resolveComponent("Menu");

  var _component_el_aside = _resolveComponent("el-aside");

  return _openBlock(), _createBlock(_component_el_aside, {
    class: "appSider",
    width: "270px"
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_Menu)];
    }),
    _: 1
  });
}