import { defineComponent, computed } from 'vue';
import { useStore } from 'vuex';
export default defineComponent({
  name: 'AppLogo',
  setup: function setup() {
    var _useStore = useStore(),
        state = _useStore.state,
        commit = _useStore.commit;

    var appTitle = computed(function () {
      return '小博考勤';
    });
    return {
      appTitle: appTitle
    };
  }
});