import url from "./url";
import { POST, GET, POSTForm, POSTExport, FORMPost } from "./apiFn";
export default {
    /*
    
     账户实体类
    
    */
    // 登录 POST
    login: (params) => POST(url.login, params),
    // 注册发送短信 POST
    sendNote: (params) => POST(url.sendNote, params),
    // 用户注册 POST
    register: (params) => POSTForm(url.register, params),
    // 用户退出登录 POST
    outLogin: (params) => POST(url.outLogin, params),
    // 修改密码
    updateUserPassword: (params) => FORMPost(url.updateUserPassword, params),
    // 找回密码
    retrievePassword: (params) => POSTForm(url.retrievePassword, params),
    // 找回密码发短信
    retrievePasswordSendNote: (params) => POST(url.retrievePasswordSendNote, params),
    /*
  
      公司信息实体类
  
    */
    // 插入公司信息 POST
    insertCompanyInfo: (params) => {
        const formData = new window.FormData();
        if (params) {
            Object.keys(params).forEach((key) => {
                const value = params[key];
                formData.append(key, value);
            });
        }
        return POSTForm(url.insertCompanyInfo, formData);
    },
    // 获取公司信息 GET
    getAccountOrgDetail: (params) => GET(url.getAccountOrgDetail, params),
    // 修改账户信息 POST
    // updateAccountOrgDetail: (params: any): Promise<any> => POST(url.updateAccountOrgDetail, params),
    updateCompanyInfo: (params) => {
        const formData = new window.FormData();
        if (params) {
            Object.keys(params).forEach((key) => {
                const value = params[key];
                formData.append(key, value);
            });
        }
        return POSTForm(url.updateCompanyInfo, formData);
    },
    /*
    
      组织控制层
    
    */
    // 添加部门信息 POST
    addOrganization: (params) => POSTForm(url.addOrganization, params),
    // 删除组织信息 POST
    deleteOrganization: (params) => FORMPost(url.deleteOrganization, params),
    // 根据条件查询人员信息 POST
    getOrgInfo: (params) => POSTForm(url.getOrgInfo, params),
    // 根据公司id获取组织的树形结构 GET
    getOrgTree: (params) => GET(url.getOrgTree, params),
    // 根据组织id获取组织下的用户信息 POST
    getOrgUser: (params) => POSTForm(url.getOrgUser, params),
    // 修改组织名称
    updateOrgName: (params) => FORMPost(url.updateOrgName, params),
    // 获取已绑定的组织
    getBindOrg: (params) => GET(url.getBindOrg, params),
    // 授权组织架信息
    getStructure: (params) => GET(url.getStructure, params),
    /*
    
      用户控制层
  
    */
    // 新增人员 POST
    addUser: (params) => POSTForm(url.addUser, params),
    // 删除公司下面的人员 POST
    deleteCompanyUser: (params) => POST(url.deleteCompanyUser, params),
    // 修改个人信息 POST
    updateUserInfo: (params) => POSTForm(url.updateUserInfo, params),
    // 未绑定设备的人
    getInUserInfo: (params) => POSTForm(url.getInUserInfo, params),
    // 已绑定设备的人
    getBindUserInfo: (params) => POSTForm(url.getBindUserInfo, params),
    // 判断人员是否关联了邀请记录
    existInviteRecord: (params) => GET(url.existInviteRecord, params),
    /*
    
      设备管理
    
    */
    // 新增设备信息 POST
    addDevice: (params) => POSTForm(url.addDevice, params),
    // 修改设备信息 POST
    updateDevice: (params) => POSTForm(url.updateDevice, params),
    // 删除设备信息 POST
    deleteDevice: (params) => POST(url.deleteDevice, params),
    // 设备授权给人员或组织 POST
    authDeviceBind: (params) => POSTForm(url.authDeviceBind, params),
    // 获取设备信息 POST
    getAllDevice: (params) => POSTForm(url.getAllDevice, params),
    // 根据sn号查询设备信息 GET
    getDeviceInfo: (params) => POSTForm(url.getDeviceInfo, params),
    // 获取设备绑定信息 GET
    getDeviceBind: (params) => GET(url.getDeviceBind, params),
    // 获取设备绑定信息和开锁类型
    getDeviceBindAndPermission: (params) => GET(url.getDeviceBindAndPermission, params),
    // 判断设备是否有绑定人员信息
    existDeviceBind: (params) => GET(url.existDeviceBind, params),
    /*
   
      开锁历史记录
   
    */
    // 获取历史开锁记录 GET
    getHistoryOpenDoorRecord: (params) => POSTForm(url.getHistoryOpenDoorRecord, params),
    // 删除开锁记录 POST
    deleteHistoryOpenDoorRecord: (params) => FORMPost(url.deleteHistoryOpenDoorRecord, params),
    // 导出开锁记录 POST
    exportHistoryOpenDoorRecord: (params) => POSTExport(url.exportHistoryOpenDoorRecord, params),
    /*
   
      访客预约单
   
   */
    // 获取所有访客预约单记录
    getAllVisitorOrder: (params) => POSTForm(url.getAllVisitorOrder, params),
    // 批量修改访客订单状态
    updateVisitorOrderStatus: (params) => FORMPost(url.updateVisitorOrderStatus, params),
    // 批量删除访客订单
    deleteVisitorOrder: (params) => FORMPost(url.deleteVisitorOrder, params),
    /*
   
      邀请访客单记录
   
    */
    // 获取所有访客记录
    getAllInviteOrder: (params) => POSTForm(url.getAllInviteOrder, params),
    // 删除邀请访客记录
    deleteInviteOrder: (params) => FORMPost(url.deleteInviteOrder, params),
    // 获取单条邀请访客记录详细信息
    getInviteOrderDetail: (params) => GET(url.getInviteOrderDetail, params),
    /*
   
      首页控制层
   
    */
    // 首页数据统计
    statisticalNumber: (params) => GET(url.statisticalNumber, params),
    // 获取顶级组织
    getTopOrg: (params) => GET(url.getTopOrg, params),
    // 首页组织构架图
    getStructureV2: (params) => GET(url.getStructureV2, params),
    // 获取50条设备信息，优先输出在线设备
    getOnlineDevice: (params) => POSTForm(url.getOnlineDevice, params),
    /*
   
      系统变量设置
   
    */
    // 系统变量设置
    addSetting: (params) => POSTForm(url.addSetting, params),
    // 修改系统变量
    // updateSetting: (params: any): Promise<any> => POST(url.updateSetting, params),
    // 获取系统变量
    getSetting: (params) => GET(url.getSetting, params),
};
