/* eslint-disable */
// import 'element-plus/packages/theme-chalk/src/base.scss';
// 架构图
import vue3TreeOrg from 'vue3-tree-org';
import "vue3-tree-org/lib/vue3-tree-org.css";
/** 如需要全局注册的组件,放开注释即可 */
const components = {};
const plugins = {
    vue3TreeOrg
};
export default function register(app) {
    // 注册element-plus组件
    Object
        .entries(components)
        .forEach(([name, component]) => app.component(name, component));
    Object
        .entries(plugins)
        .forEach(([name, plugin]) => app.use(plugin));
    // 架构图
    app.config.globalProperties.$ELEMENT = { size: 'large' };
}
