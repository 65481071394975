export const timestamp = () => +Date.now();
export const clamp = (n, min, max) => Math.min(max, Math.max(min, n));
export const noop = () => { };
export const now = () => Date.now();
import bsjLocalStorage from "../class/localStorage";
export function getLSMainObj() {
    return new bsjLocalStorage('main');
}
/**
 * @description:  设置ui装载节点
 */
export function getPopupContainer(node) {
    if (node) {
        return node.parentNode;
    }
    return document.body;
}
/**
 * 将对象作为参数添加到URL
 * @param baseUrl url
 *
 * @param obj
 * @returns {string}
 * eg:
 *  let obj = {a: '3', b: '4'}
 *  setObjToUrlParams('www.baidu.com', obj)
 *  ==>www.baidu.com?a=3&b=4
 */
export function setObjToUrlParams(baseUrl, obj) {
    let parameters = '';
    let url = '';
    for (const key in obj) {
        parameters += key + '=' + encodeURIComponent(obj[key]) + '&';
    }
    parameters = parameters.replace(/&$/, '');
    if (/\?$/.test(baseUrl)) {
        url = baseUrl + parameters;
    }
    else {
        url = baseUrl.replace(/\/?$/, '?') + parameters;
    }
    return url;
}
export function deepMerge(src, target) {
    let key;
    for (key in target) {
        src[key] =
            src[key] && src[key].toString() === '[object Object]'
                ? deepMerge(src[key], target[key])
                : (src[key] = target[key]);
    }
    return src;
}
/**
 * @description: 根据数组中某个对象值去重
 */
export function unique(arr, key) {
    const map = new Map();
    return arr.filter((item) => {
        const _item = item;
        return !map.has(_item[key]) && map.set(_item[key], 1);
    });
}
/**
 * @description: es6数组去重复
 */
export function es6Unique(arr) {
    return Array.from(new Set(arr));
}
/**
 * @desc   转化树形图
 * @param  {data, attributes}
 * @return {Array}
 */
export function toTreeData(data, attributes) {
    const dataCopy = JSON.parse(JSON.stringify(data));
    // toTree
    const map = new Map(dataCopy && dataCopy.map((item) => [item[attributes.id], item]));
    const treeData = [];
    dataCopy && dataCopy.map((item) => {
        const parent = map.get(item[attributes.parentId]);
        if (parent) {
            (parent.children || (parent.children = [])).push(item); // parent 引用 item
        }
        else {
            treeData.push(item);
        }
    });
    return treeData;
}
/**
 * @description: 时间差值
 */
export function timesFun(curDate, nowDate) {
    //如果时间格式是正确的，那下面这一步转化时间格式就可以不用了
    var dateBegin = new Date(curDate.replace(/-/g, "/")); //将-转化为/，使用new Date
    var dateEnd = new Date(nowDate.replace(/-/g, "/")); //获取当前时间
    var dateDiff = dateEnd.getTime() - dateBegin.getTime(); //时间差的毫秒数
    var dayDiff = Math.floor(dateDiff / (24 * 3600 * 1000)); //计算出相差天数
    var leave1 = dateDiff % (24 * 3600 * 1000); //计算天数后剩余的毫秒数
    var hours = Math.floor(leave1 / (3600 * 1000)); //计算出小时数
    //计算相差分钟数
    var leave2 = leave1 % (3600 * 1000); //计算小时数后剩余的毫秒数
    var minutes = Math.floor(leave2 / (60 * 1000)); //计算相差分钟数
    //计算相差秒数
    var leave3 = leave2 % (60 * 1000); //计算分钟数后剩余的毫秒数
    var seconds = Math.round(leave3 / 1000);
    var timesString = "";
    if (dayDiff != 0) {
        timesString = dayDiff + "天" + hours + "小时" + minutes + "分";
    }
    else if (dayDiff == 0 && hours != 0) {
        timesString = hours + "小时" + minutes + "分";
    }
    else if (dayDiff == 0 && hours == 0) {
        timesString = minutes + "分";
    }
    return timesString;
}
// get 所有数据过滤 前后空格
export function clearDataTrim(data) {
    if (!data || Object.keys(data).length == 0) {
        return data;
    }
    for (let key in data) {
        if (typeof data[key] === 'string') {
            data[key] = data[key].trim();
        }
    }
    return data;
}
// 返回是否独立项目
export function getQianKunFlag() {
    return true;
    if (window.__POWERED_BY_QIANKUN__) {
        return true;
    }
    return false;
}
// 防抖
export const debounce = (function () {
    let timer = 0;
    return function (callback, ms) {
        clearTimeout(timer);
        timer = setTimeout(callback, ms);
    };
})();
// 是否全屏
export function isFullscreenFn() {
    let res = document.fullscreen ||
        document.msFullscreenElement ||
        document.mozFullScreen ||
        document.webkitIsFullScreen || false;
    return res;
}
// 退出全屏
export function ExitFullscreen() {
    //W3C
    if (document.exitFullscreen) {
        document.exitFullscreen();
    }
    //FireFox
    else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
    }
    //Chrome等
    else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
    }
    //IE11
    else if (document.msExitFullscreen) {
        document.msExitFullscreen();
    }
}
// 全屏
export function Fullscreen(dom = null) {
    const docElm = dom || document.documentElement;
    //W3C
    if (docElm.requestFullscreen) {
        docElm.requestFullscreen();
    }
    //FireFox
    else if (docElm.mozRequestFullScreen) {
        docElm.mozRequestFullScreen();
    }
    //Chrome等
    else if (docElm.webkitRequestFullScreen) {
        docElm.webkitRequestFullScreen();
    }
    //IE11
    else if (docElm.msRequestFullscreen) {
        docElm.msRequestFullscreen();
    }
}
export const TwoCoordinateAzimuth = function (start, end) {
    let d = 0, lat_a = (start.lat * Math.PI) / 180, lng_a = (start.lng * Math.PI) / 180, lat_b = (end.lat * Math.PI) / 180, lng_b = (end.lng * Math.PI) / 180;
    d =
        Math.sin(lat_a) * Math.sin(lat_b) +
            Math.cos(lat_a) * Math.cos(lat_b) * Math.cos(lng_b - lng_a);
    d = Math.sqrt(1 - d * d);
    d = (Math.cos(lat_b) * Math.sin(lng_b - lng_a)) / d;
    d = (Math.asin(d) * 180) / Math.PI;
    d = d % 360;
    //判断在终点在第几象限
    const x = end.lng - start.lng, y = end.lat - start.lat;
    if (x < 0 && y > 0) {
        d = 360 + d;
    }
    else if (y < 0) {
        d = 180 - d;
    }
    d = d >= 0 ? d : 360 + d;
    return d;
};
export const deepClone = function (source) {
    if (!source && typeof source !== "object") {
        throw new Error("error arguments");
    }
    const targetObj = (source.constructor === Array ? [] : {});
    Object.keys(source).forEach(keys => {
        if (source[keys] && typeof source[keys] === "object") {
            targetObj[keys] = deepClone(source[keys]);
        }
        else {
            targetObj[keys] = source[keys];
        }
    });
    return targetObj;
};
