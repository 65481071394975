function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import { reactive, toRefs, watch } from 'vue';
import VisitorProfile from '../VisitorProfile/index.vue';
import OrgInfo from '../OrgInfo/index.vue';
import PasswordSetting from '@/components/PasswordSetting/PasswordSetting.vue';
export default {
  name: 'setup',
  components: {
    VisitorProfile: VisitorProfile,
    OrgInfo: OrgInfo,
    PasswordSetting: PasswordSetting
  },
  props: {
    setTopOrgId: {
      type: Boolean,
      default: false
    }
  },
  setup: function setup(props) {
    var refData = reactive({
      data: ['访客资料设置', '组织信息设置', '密码设置'],
      visitorProfileShow: false,
      orgInfoShow: false,
      passwordSettingShow: false
    });
    watch(function () {
      return props.setTopOrgId;
    }, function () {
      refData.orgInfoShow = props.setTopOrgId;
    }, {
      immediate: true
    }); // 点击

    var onClick = function onClick(index) {
      switch (index) {
        case 0:
          // 访客资料设置
          refData.visitorProfileShow = true;
          break;

        case 1:
          // 组织信息设置
          refData.orgInfoShow = true;
          break;

        case 2:
          // 密码设置
          refData.passwordSettingShow = true;
          break;

        default:
          console.error('报错');
          break;
      }
    };

    return _objectSpread(_objectSpread({}, toRefs(refData)), {}, {
      onClick: onClick
    });
  }
};