import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-2ffbe915"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "menu_outer"
};
var _hoisted_2 = {
  class: "menu_title"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_Icon = _resolveComponent("Icon");

  var _component_el_menu_item = _resolveComponent("el-menu-item");

  var _component_el_menu = _resolveComponent("el-menu");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_menu, {
    "default-active": _ctx.defaultActive,
    "menu-trigger": "click",
    "unique-opened": "",
    "text-color": "#fff",
    "active-text-color": "#3075FB",
    onSelect: _ctx.handleSelect
  }, {
    default: _withCtx(function () {
      return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rootMenus, function (menu) {
        return _openBlock(), _createBlock(_component_el_menu_item, {
          key: menu.index,
          index: menu.index,
          "popper-class": "abdd"
        }, {
          title: _withCtx(function () {
            return [menu.icon ? (_openBlock(), _createBlock(_component_Icon, {
              key: 0,
              name: menu.icon
            }, null, 8, ["name"])) : _createCommentVNode("", true), _createElementVNode("span", _hoisted_2, _toDisplayString(menu.title), 1)];
          }),
          _: 2
        }, 1032, ["index"]);
      }), 128))];
    }),
    _: 1
  }, 8, ["default-active", "onSelect"])]);
}