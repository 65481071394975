import { ElMessage } from 'element-plus';
let messageInstance = null;
export function useMessage() {
    function success(options) {
        if (messageInstance) {
            messageInstance.close();
        }
        messageInstance = ElMessage.success(options);
    }
    function error(options) {
        if (messageInstance) {
            messageInstance.close();
        }
        messageInstance = ElMessage.error(options.msg ? options.msg : options);
    }
    function warning(options) {
        if (messageInstance) {
            messageInstance.close();
        }
        messageInstance = ElMessage.warning(options);
    }
    function info(options) {
        if (messageInstance) {
            messageInstance.close();
        }
        messageInstance = ElMessage.info(options);
    }
    return {
        success,
        error,
        warning,
        info
    };
}
