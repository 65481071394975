import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-d8803a54"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "setup__list"
};
var _hoisted_2 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_Icon = _resolveComponent("Icon");

  var _component_el_popover = _resolveComponent("el-popover");

  var _component_VisitorProfile = _resolveComponent("VisitorProfile");

  var _component_OrgInfo = _resolveComponent("OrgInfo");

  var _component_PasswordSetting = _resolveComponent("PasswordSetting");

  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_el_popover, {
    "popper-class": "setup",
    placement: "bottom",
    width: 160,
    trigger: "click"
  }, {
    reference: _withCtx(function () {
      return [_createVNode(_component_Icon, {
        name: "Setting",
        class: "icon"
      })];
    }),
    default: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, function (item, index) {
        return _openBlock(), _createElementBlock("p", {
          key: index,
          onClick: function onClick($event) {
            return $setup.onClick(index);
          }
        }, _toDisplayString(item), 9, _hoisted_2);
      }), 128))])];
    }),
    _: 1
  }), _createVNode(_component_VisitorProfile, {
    visible: _ctx.visitorProfileShow,
    "onUpdate:visible": _cache[0] || (_cache[0] = function ($event) {
      return _ctx.visitorProfileShow = $event;
    })
  }, null, 8, ["visible"]), _createVNode(_component_OrgInfo, {
    visible: _ctx.orgInfoShow,
    "onUpdate:visible": _cache[1] || (_cache[1] = function ($event) {
      return _ctx.orgInfoShow = $event;
    })
  }, null, 8, ["visible"]), _createVNode(_component_PasswordSetting, {
    visible: _ctx.passwordSettingShow,
    "onUpdate:visible": _cache[2] || (_cache[2] = function ($event) {
      return _ctx.passwordSettingShow = $event;
    })
  }, null, 8, ["visible"])], 64);
}