export default class bsjLocalStorage {
    // public name:string;
    constructor(name) {
        this.name = name;
    }
    getKeyName(key) {
        return this.name + '_' + key;
    }
    // 取
    getLocalStorage(key) {
        return localStorage.getItem(this.getKeyName(key));
    }
    // 存
    setLocalStorage(key, val) {
        return localStorage.setItem(this.getKeyName(key), JSON.stringify(val));
    }
    // 删
    removeLocalStorage(key) {
        return localStorage.removeItem(this.getKeyName(key));
    }
}
